import { Route, Routes } from 'react-router-dom';
// import './App.css'

import Homepage2 from './NewPages/Homepage2';
import Contactnew from './NewPages/Contact';
import DigitalLendingNew from './NewPages/DigitalLending';
import ComplianceRegulatoryInfo from './NewPages/ComplianceRegulatoryInfo';


function App() {
  return (
    <div className='App'>
      <Routes>
        <Route index element={<Homepage2 />} />
        <Route path='/contact' element={<Contactnew />} />
        <Route path='/digital_lending' element={<DigitalLendingNew />} />
        <Route path='/nbfccertificate' element={<ComplianceRegulatoryInfo />} />    
      </Routes>
   
    </div>
  );
}

export default App;
