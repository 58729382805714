import React, { useEffect } from 'react'
import { AnimationOnScroll } from "react-animation-on-scroll";
import 'animate.css';
import Navbar from '../components/Navbar/index';
import Footer from '../components/Footers/Footer';
import '../css/Homepage.scss';
import bannerImg from '../assets/banner-img(new).webp';
// import bannerImg from '../assets/banner-img(new).png';
import digitalLending from '../assets/digitallendingsection.webp'
// import digitalLending from '../assets/digitallendingsection.png'
import DirectBusinessLending from '../assets/Directdigitallendingsection.webp'
// import DirectBusinessLending from '../assets/Directdigitallendingsection.png'
import MissionVision from '../assets/MissionVision.webp'
// import MissionVision from '../assets/MissionVision.png'
import fuserfriendly from '../assets/fuserfriendly.png'
import fonlineApplication from '../assets/fonlineApplication.png'
import fsecuredocuments from '../assets/fsecuredocuments.png'
import ftimemanagement from '../assets/ftimemanagement.png'
import fpolicies from '../assets/fpolicies.png'
import floan from '../assets/floan.png'
import fonlineapproval from '../assets/fonlineapproval.png'
import fdemataccount from '../assets/fdemataccount.png'
import fsignature from '../assets/fsignature.png'
import credfinLogo from '../assets/credfinLogo.webp'
import QuidLogo from '../assets/QuidLogo.png'
import KreditRupeeIcon from '../assets/KreditRupeeIcon.png'
// import credfinLogo from '../assets/credfinLogo.png'
function Homepage2() {
    useEffect(() => {
        setTimeout(() => {

            const bannerH1 = document.getElementById('banner_heading');
            if (bannerH1) {
                bannerH1.style.fontSize = '60px';
            }
        }, 300)
    }, []);
    return (
        <>
            <Navbar />
            <div className="banner circle-shape">
                <div className="container">
                    <div className="row mt-1" >
                        <div className="col-xl-7 col-xs-12 mt-3 pe-5">
                            <h1 id='banner_heading'>
                                Empowering <span>Financial Futures Through</span> Innovative Solutions
                            </h1>
                            <p>At Arham Finance & Investment Services Ltd., we are dedicated to revolutionising the financial landscape by delivering cutting-edge, user-centric solutions. </p>
                        </div>
                        <div className="col-xl-5 col-xs-12 mt-3">
                            <div className="circle circle-shape">
                                <img src={bannerImg} width={'90%'} alt="banner_img" loading='lazy' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Aboutsection circle-shape" id="Aboutus">
                <div className="container">
                    <div className="row heading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1>About Us</h1>
                        </AnimationOnScroll>
                        <div className="bar"></div>
                        <p>Arham Finance is an Indian Non-Banking Finance Company that specializes in Small and Medium Enterprises (SMEs) lending, Digital Lending and Wealth Management. The company was incorporated on 29th September 1992 and is registered at the Reserve Bank of India (RoC)-Delhi. Arham has been operating in the business of business services for the past 32 years. The company provides a full range of solutions to complement its services. We are a client focused company with emphasis on operational efficiency & uncompromising commitment to core values..</p>
                    </div>
                </div>
            </div>

            <div className="Missionsection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-xs-12 textcolone" id="leftcol">
                            <p>Our mission is to make finance accessible and contribute to the economic development of the nation, backed by a team of experts who are committed to delivering exceptional service and ensuring the long-term success of our clients</p>
                        </div>
                        <div className="col-lg-4 col-xs-12 imgcol" id="centercol">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <img src={MissionVision} alt="mission_vision_image" />
                            </AnimationOnScroll>
                        </div>
                        <div className="col-lg-4 col-xs-12 textcoltwo" id="rightcol">
                            <p>Our vision is to empower and support the growth of small and medium enterprises in India by providing innovative financial solutions and dedicated wealth management services. We strive to be a trusted partner for businesses, leveraging digital lending technologies to streamline the borrowing process and maximize financial success.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ProductSection" id="Product">
                <div className="container">
                    <div className="row heading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1>Products</h1>
                        </AnimationOnScroll>
                    </div>
                    <div className="row section-one">

                        <div className="col-lg-6 col-xs-12 columnfirst mt-3">
                            <div className="imgsection">
                                <div className="imginner">
                                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                                        {" "}
                                        <img src={digitalLending} alt="Digital Lending" />
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12 textsection mt-3">
                            <h2>Digital lending</h2>
                            <div className="bar"></div>
                            <p>At Arham Finance, we are committed to revolutionizing the lending landscape through our cutting-edge digital lending solutions. With a focus on innovation and customer-centricity, our digital lending offerings are designed to empower businesses and individuals with seamless access to credit.</p>
                        </div>
                    </div>
                    <div className="row section-two">
                        <div className="col-lg-6 col-xs-12 textsection mt-3" id="contentColumn">
                            <h2>Direct Business lending</h2>
                            <div className="bar"></div>
                            <p>At Arham Finance, we understand the pivotal role that small and medium enterprises (SMEs) play in driving economic growth and fostering innovation. Our specialized SME lending solutions are tailored to meet the unique financing needs of small businesses, empowering them to pursue growth opportunities and achieve their business aspirations</p>
                        </div>
                        <div className="col-lg-6 col-xs-12 columnfirst mt-3" id="imagecolumn">
                            <div className="imgsection">
                                <div className="imginner">
                                    <AnimationOnScroll animateIn="animate__fadeInRight">
                                        {" "}
                                        <img src={DirectBusinessLending} alt="Direct Business Lending" />
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div className="features" id="features">
                <div className="container">
                    <div className="row heading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1>Features of Our Digital Lending App</h1>
                        </AnimationOnScroll>
                    </div>
                    <div className="row cards">
                        <FeaturesCard image={fuserfriendly} heading={'User-Friendly Interface'} textbelow={'It streamlines the loan application, ensuring borrowers find it easy to navigate and complete the process.'} />
                        <FeaturesCard image={fonlineApplication} heading={'Online Application'} textbelow={'Effortlessly complete the entire loan application online, including personal details and document uploads.'} />
                        <FeaturesCard image={fsecuredocuments} heading={'Secure Document Submission'} textbelow={'Facilitates secure electronic document submission for borrowers, ensuring privacy and compliance.'} />
                        <FeaturesCard image={ftimemanagement} heading={'Real-Time Application Tracking'} textbelow={'Monitor loan application status in real time for instant progress visibility.'} />
                        <FeaturesCard image={floan} heading={'Personalized Loan Offers'} textbelow={'Offers customized loans based on the borrower\'s financial profile, enhancing the relevance of presented options.'} />
                        <FeaturesCard image={fpolicies} heading={'Transparent Terms and Conditions'} textbelow={'Empowers borrowers with clear and transparent loan details for informed decisions.'} />
                        <FeaturesCard image={fonlineapproval} heading={'Instant Approval Decisions'} textbelow={'Utilizes automated underwriting for swift approval decisions, minimizing loan application waiting times.'} />
                        <FeaturesCard image={fdemataccount} heading={'Secure Account Management'} textbelow={'Streamlined account portal for borrowers to manage loan details, repayments, and statements effectively.'} />
                        <FeaturesCard image={fsignature} heading={'Electronic Signatures'} textbelow={'Facilitates digital signing of loan agreements, streamlining the process without physical paperwork.'} />
                    </div>
                </div>
            </div>
            <div className="sourcingPartner circle-shape-one" id="sourcingpartner">
                <div className="container">
                    <div className="circle circle-shape-two">
                        <div className="row heading">
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                {" "}
                                <h1>Our Sourcing Partner</h1>
                            </AnimationOnScroll>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-4 col-md-6 col-xs-12">
                                <div className="sectionsfirst" style={{padding:'20px',height:'380px'}}>

                                    <div className="box" style={{ background: '#fff',padding:'20px 25px', textAlign: 'center', borderRadius: '30px' }}>
                                        <img src={credfinLogo} alt="credfin_logo" width={'100%'} />
                                    </div>
                                    <div className="text" style={{margin:'20px 0px'}}>
                                        <h5 className='text-center' style={{color:'#fff',fontSize:'24px'}}>Credfin Services Private Limited</h5>
                                        <button className='btn1' style={{fontSize:'20px',color:'#fff',border:'none',background:'#084A6c',padding:'12px 22px',cursor:'pointer',marginTop:'20px',borderRadius:'12px',boxShdaow:"-2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 3px 8px 0px rgba(255, 255, 255, 0.4) inset"}}><a style={{color:'#fff',textDecoration:"none"}} href="https://credfin.money/" target="_blank">Credfin →</a></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-xs-12">
                                <div className="sectionsfirst" style={{padding:'20px',height:'380px'}}>
                                    <div className="box" style={{ background: '#fff',padding:'20px 25px', textAlign: 'center', borderRadius: '30px' }}>
                                        <img src={QuidLogo} alt="QuidLogo" width={'90%'} />
                                    </div>
                                    <div className="text" style={{margin:'20px 0px'}}>
                                        <h5 className='text-center' style={{color:'#fff',fontSize:'24px'}}>Quid Innovations Private Limited</h5>
                                        <button className='btn1' style={{fontSize:'20px',color:'#fff',border:'none',background:'#084A6c',padding:'12px 22px',cursor:'pointer',marginTop:'20px',borderRadius:'12px',boxShdaow:"-2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 3px 8px 0px rgba(255, 255, 255, 0.4) inset"}}><a style={{color:'#fff',textDecoration:"none"}} href="https://quid.money/" target="_blank">Quid →</a></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-xs-12">
                                <div className="sectionsfirst" style={{padding:'20px',height:'380px'}}>
                                    <div className="box" style={{ background: '#fff',padding:'20px 25px', textAlign: 'center', borderRadius: '30px' }}>
                                        <img src={KreditRupeeIcon} alt="KreditRupeeIcon"  width={'45%'}/>
                                    </div>
                                    <div className="text" style={{margin:'20px 0px'}}>
                                        <h5 className='text-center' style={{color:'#fff',fontSize:'24px'}}>Mobavanue media private limited</h5>
                                        <button className='btn1' style={{fontSize:'20px',color:'#fff',border:'none',background:'#084A6c',padding:'12px 22px',cursor:'pointer',marginTop:'20px',borderRadius:'12px',boxShdaow:"-2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 3px 8px 0px rgba(255, 255, 255, 0.4) inset"}}><a style={{color:'#fff',textDecoration:"none"}} href="https://www.kreditrupee.com" target="_blank">Kredit Rupee →</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row sectionsfirst">
                            <div className="col-lg-4 col-xs-12 imgs">
                                <div className="box">
                                    <img src={credfinLogo} alt="credfin_logo" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-xs-12 textsection">
                                <p>Our app offers convenience, speed, and robust data protection, ensuring a seamless and secure borrowing experience. With our user-friendly interface and advanced features, you can get the funds you need quickly.</p>
                                <button className='btn1'><a href="https://credfin.money/" target="_blank">CredFin →</a></button>
                            </div>
                        </div>
                        <div className="row sectionsfirst">
                            <div className="col-lg-4 col-xs-12 imgs">
                                <div className="box">
                                    <img src={QuidLogo} alt="QuidLogo" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-xs-12 textsection">
                                <p>Quid Innovations Private Limited<br />One Seamless Platform for Personalised, Effortless and Unified Financial Opportunities</p>
                                <button className='btn1'><a href="https://quid.money/" target="_blank">Quid →</a></button>
                            </div>
                        </div>
                        <div className="row sectionsfirst">
                            <div className="col-lg-4 col-xs-12 imgs">
                                <div className="box">
                                    <img src={KreditRupeeIcon} alt="KreditRupeeIcon" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-xs-12 textsection">
                                <p>Mobavanue media private limited<br />One Seamless Platform for Personalised, Effortless and Unified Financial Opportunities</p>
                                <button className='btn1'><a href="https://www.kreditrupee.com" target="_blank">Kredit →</a></button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}
export default Homepage2


function FeaturesCard({ image, heading, textbelow }) {
    return (
        <>
            <div className="col-xl-4 col-sm-6 col-xs-12 mt-4">
                <div className="columns">
                    <div className="row">
                        <div className="col-3">
                            <img src={image} alt="card_image" />
                        </div>
                        <div className="col-9">
                            <h3>{heading}</h3>
                            <p>{textbelow}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
